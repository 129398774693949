import React, { useState } from 'react';
import { Trans } from 'react-i18next';

import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { CreateJobDialog } from './CreateJobDialog';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_COLORS, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { Grid } from '@mui/material';

export interface HashAttributes {
  timestamp: number;
  hash: string;
}

export const EmptyJobView: React.FC = () => {
  /* LOCAL STATE */
  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    const videoElem = document.getElementById('video');

    (videoElem as HTMLVideoElement).pause();

    setIsCreateJobDialogOpen(true);
  };

  return (
    <DashboardLayout>
      <Box mb={1}>
        <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>jobPage.title</Trans>
        </Typography>
        <Typography
          variant="button"
          color={TYPOGRAPHY_COLORS.TEXT}
          fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
          <Trans>jobPage.cta</Trans>
        </Typography>
      </Box>
      <Box mb={2}>
        <Button
          variant={BUTTON_VARIANT.GRADIENT}
          size={BUTTON_SIZE.SMALL}
          color={BUTTON_COLOR.INFO}
          onClick={handleOpenDialog}>
          <Trans>jobPage.createJob</Trans>
        </Button>
      </Box>
      <Grid container>
        <Grid item xs={12}>
          <video
            id="video"
            width="100%"
            controls
            style={{ borderRadius: '15px' }}
            disablePictureInPicture
            controlsList="nodownload noplaybackrate">
            <source
              src="https://della-storage-one.de-fra1.upcloudobjects.com/dellahu/della_interview.mp4"
              type="video/mp4"
            />
          </video>
        </Grid>
      </Grid>

      <CreateJobDialog open={isCreateJobDialogOpen} setOpen={setIsCreateJobDialogOpen} />
    </DashboardLayout>
  );
};
