export default {
  generic: {
    welcome: 'Welcome',
    login: 'Login',
    support: 'Support',
    logout: 'Logout',
    viewAll: 'View All',
    loading: 'Loading...',
    viewAllJobs: 'All jobs',
    openFacebook: 'Get help from Facebook Page',
    openMessenger: 'Write as via Facebook Messager',
    openEmail: 'Write us',
    accept: 'Accept',
    postCode: 'PostCode',
    city: 'City',
    street: 'Street',
    streetNumber: 'Street number',
    cancel: 'Cancel',
    save: 'Save',
    OK: 'OK',
    hour: 'hour',
    add: 'Add new',
    location: 'Location',
    previous: 'Previous',
    hourly: 'Hourly',
    monthly: 'Monthly',
    noData: 'No data at the moment',
    yes: 'Yes',
    no: 'No',
    backToLogin: 'Back to login',
    backToHome: 'Back to the homepage',
    net: 'Net',
    back: 'Back',
    register: 'Registration',
    male: 'Male',
    female: 'Female',
    decline: 'Decline',
    loginUpsell: 'Young, flexible and dynamic workforce at your fingertips',
    month: 'month',
    sign: 'Sign',
    studetnOrg: 'HelloStudent Student Organization',
    noti: 'Notification',
    look: 'Look',
    notFound: 'Page not found',
  },
  menu: {
    home: 'Home',
    jobs: 'Jobs',
    notifications: 'Notifications',
    profile: 'Profile',
    support: 'Support',
    management: 'Management',
  },
  jobPage: {
    data: 'Details',
    workForce: 'Workforce',
    activeJobs: 'Active jobs',
    inactiveJobs: 'Inactive jobs',
    title: 'Have a look at our students',
    activeWorkers: 'Active workers',
    applicants: 'Applicants',
    invited: 'Invited workers',
    cta: 'Create a job in order to be able to be able to employ them',
    jobDetailPageTitle: 'Job Details:',
    createRole: 'Create role',
    createJob1: 'Create job 1',
    createJob: 'Create job',
    createRoleHelper: 'In order to create a Job, create a role first',
    noJobsYet: 'Your job list is empty, create new job.',
    hoursPerMonth: 'Hours/week',
    worker: 'Worker',
    workers: 'Workers',
    expectedWorkingHours: 'Expected working hours',
    financialDetails: 'Financial details',
    terminateJob: 'Close job',
    editJob: 'Edit job',
    workerInvitationRollback: 'Cancel application',
    terminatedJobInfo: 'This job cannot be edited, it already has been closed',
    jobHasBeenTerminated: 'Job is successfully terminated',
    jobTerminationFailed: 'Job termination is failed',
    terminateJobAlertTitle: 'Would you like to terminate the job?',
    terminateJobAlertBody:
      'We will rollback the applications and we will create their confirmation',
    removeWorkersFromJobDialog: {
      title: 'Cancel application',
      helperText: 'You can select one or more worker to cancel their application',
      selectedWorkers: 'Selected worker: {{number}}',
      quitWorkerFromJob: 'Cancel for {{number}} worker',
      teminateWorkerFailed: 'Worker cancelation failed.',
      cancelJobApplicationFailed: 'Worker cancelation failed',
      cancelJobApplicationSuccess: 'Worker application cancelled successfully',
      acceptJobApplicationFailed: 'Accepting worker application failed',
      acceptJobApplicationSuccess: 'Worker application accepted successfully',
    },
    createJobForm: {
      hourlyWage: 'Hourly Wage',
      hourlyNetAmount: 'Hourly net amount: {{amount}}',
      hourlyComission: 'Cost of the student organisation: {{amount}}',
      hourlyGrossWage: 'Hourly gross amount: {{amount}}',
      hourlyPersonalTax: 'Hourly personal tax: {{amount}}',
      hourlyAmountToReceive: 'Hourly net amount student receives: {{amount}}',
      hourlyAmountYouPay: 'Hourly amount you pay: ',
      totalNetAmount: 'Monthly net amount: {{amount}}',
      totalComission: 'Monthly of the student organisation: {{amount}}',
      totalGrossWage: 'Monthly gross amount: {{amount}}',
      totalPersonalTax: 'Total personal tax: {{amount}}',
      totalAmountToReceive: 'Total amount student receives: {{amount}}',
      selectRole: 'Select role',
      buttonLabel: 'Create job',
      netWage: 'Net wage',
      personalTax: 'Personal tax',
      grossWage: 'Gross wage',
      comission: 'Comission',
      amountYouPay: 'Amount you pay',
      confirm: 'Confirm',
      inviteTitle: 'Select workers to invite',
      selectedWorkers: 'Selected workers count: ',
      confirmTitle: 'Confirm job details',
      success: 'Job has been created successfully',
      error: 'There was an error while creating job',
      editSuccess: 'Job has been updated successfully',
      editError: 'There was an error while updating job',
      confirmCloseTitle: 'Are you sure you want to exit?',
      confirmCLoseText: 'Your changes will not be saved.',
      editButtonLabel: 'Edit job',
      longTermJob: 'Long term job',
      shortTermJob: 'Short term job',
      timeSchedule: 'How many hours needed? Which days? When?',
      workerCount: 'How many workers needed? (You can modify later)',
      startNow: 'Job starts immediately',
      girlsAndBoys: 'We accept applications from both girls and boys',
      girls: 'We accept applications from girls.',
      boys: 'We accept applications from boys',
      language: 'Language skills',
      hungarianSkills: 'Hungarian language skills needed',
      drivingLicense: 'Driving license needed?',
      documents: 'Which documents are needed to do the job?',
      documentsPlaceholder: 'e.g. Health booklet, lung filter',
      extraSkills: 'Skills, experience and other requirements',
      noLicense: 'Not needed',
      car: 'Car',
      motorCycle: 'Motorcycle',
      englishSkills: 'English language skills needed',
      bonus: 'Bonus for the students:',
      bonusPlaceholder: 'eg. Free transfer, free meals or housing',
      contactPerson: 'Contact name',
      contactPhone: 'Phonenumber',
      contactEmail: 'Email',
      equipmentInfo: 'How should the worker arrive?',
      equimentInfoPlaceHolder:
        'eg. Wear white shirts. We wxpect you to arrive 15 mins before the shift',
      arriveInfo: 'Who and where should the student be looking for',
      arrivePlaceholder:
        'pl. Look for John Smith at the reception and ask for an entry to the office number 106.',
      under25: 'Expenses in case of a student below 25 years old',
      over25: 'Expenses in case of a student above 25 years old',
      breakAlert:
        '* Amennyiben a feladatteljesítés tartama a napi hat órát meghaladja, napi húsz, ha a napi kilenc órát meghaladja, további napi huszonöt perc munkaközi szünetet kell biztosítani. Az iskolaszövetkezet nappali tagozatos tanuló részére, ha két egymást követő napon végzi feladatát, a napi munka befejezése és a következő napi munka megkezdése között legalább tizenegy óra napi pihenőidőt kell biztosítani.',
      breakAlertTitle: 'A munkatörvény által előírt kötelező szünet a diákok számára.',
      isInterviewNeeded: 'Should we make a video interview with the students? (no additional fee)',
      incorrectWage: 'Incorrect gross wage amount',
    },
    createJobModal: {
      title: 'Do you want to create the below job?',
      jobTitle: 'Job title: {{jobTitle}}',
      jobDescription: 'Job description: {{jobDescription}}',
      jobLocation: 'Job location: {{jobLocation}}',
      hourlyWage: 'Job hourly wage: {{hourlyWage}}',
      chooseOrCreate: 'Choose an existing role or create new',
      create: 'Create new role',
      expectedWorkingHours: 'Expected weekly hours',
      helperText1: 'Please set the average amount of time the student will work',
      helperText2:
        'These numbers are calculated based on the weekly working hours and hourly wage in case the student works 4 weeks a month.',
      helperText3:
        'You find the workers you already worked with in the following list, if you would like to add a new worker, please click on the add new button.',
      helperText4: 'Min. weekly hours is 1 and max is 48',
      contactPerson: 'Contact person name',
      instructions: 'First day instructions',
      recruitmentType: 'Is recruitment needed?',
    },
    createRoleForm: {
      jobTitle: 'Role Title',
      jobDescription: 'Role Description',
      submitButtonLabel: 'Continue',
      companyProfile: 'What is the company profile?',
    },
    createRoleModal: {
      title: 'Do you want to create the below role?',
      roleTitle: 'Role title: {{roleTitle}}',
      roleDescription: 'Role description: {{roleDescription}}',
      roleLocation: 'Role location: {{roleLocation}}',
    },
    createJobOfferModal: {
      title: 'Select worker you want to invite',
      workerSelectTitle: 'Select student',
      jobSelectTitle: 'Select job',
      invite: 'Invite',
      noWorker: 'No workers invited',
      noJob: 'No works uploaded',
      createJobOffer: {
        success: 'Job offer created',
        error: 'Failed to create job offer',
      },
    },
    editJobModal: {
      cancelTitle: 'This job cannot be edited',
      cancelDescription:
        'There are valid contracts associated with this role/job, you cannot edit it, alternatively you can cancel this role, create a new one and reinvite your workers',
    },
  },
  alerts: {
    phoneNumberValidate: {
      error: 'Invalid phone number',
    },
    smsSending: {
      error: 'Sms sending failed. Are You online?',
    },
    verificationCode: {
      error: 'Invalid password or email, try again!',
    },
    passwordReset: {
      error: 'Error occurred while sending password reset',
      success: 'Password reset email sent successfully',
    },
    registration: {
      error: 'Registration failed, please try again!',
    },
    getJobOffer: {
      error: 'Error occurred while downloading job offers',
    },
    acceptJobOffer: {
      error: 'Error occurred while accepting the job offer',
      success: 'You have successfully accepted the job offer',
    },
    declineJobOffer: {
      error: 'Error occurred while rejecting the job offer',
      success: 'You have successfully rejected your job offer',
    },
    calendar: {
      error: 'Error occurred while creating the calendar entry',
      success: 'We have successfully added the events to your calendar',
    },
    map: {
      error: 'We were unable to query the work location data.',
    },
    applyJob: {
      error: 'Error occurred while applying. Please try again',
      success: 'You have successfully applied for the job',
    },
    cancelJobApplication: {
      error: 'Error occurred while canceling the application.  Please try again',
      success: 'You have successfully canceled your application.',
    },
    getActiveJobs: {
      error: 'Error occurred while retrieving your current jobs',
    },
    getPendingJobApplications: {
      error: 'Error occurred while retrieving your job applications',
    },
    getJobConfirmations: {
      error: 'Failed to get job confirmations',
    },
    acceptJobConfirmations: {
      error: 'Job confirmation failed',
      success: 'Successfully confirmed {{hoursWorked}} hours',
    },
    getAccount: {
      error: 'Fetching financial data failed',
    },
    signContract: {
      error: 'Signing contract failed, pleasy try again later',
      success:
        'You have successfully signed the contract. We will send it in email for you shortly',
    },
  },
  alertPopup: {
    notSufficientData: {
      title: 'Incorrect data',
      helperText: 'Please upload your student ID',
      accept: 'Upload now',
      decline: 'Later',
    },
    cancelApplication: {
      title: 'Are you sure you want to cancel your application?',
      helperText: 'If you change your mind later, you will need to sign up again!',
      accept: 'Withdrawal',
      decline: 'Close',
    },
    notANumber: 'Must be a number',
  },
  validation: {
    required: 'Required',
    minLength: 'Must be at least {{number}} long',
    invalidEmail: 'Invalid email',
    invalidPhone: 'Invalid phone number',
    minAndMaxlength: 'Must be at least {{min}} and at most {{max}} character long',
    minimum6Char: 'Minimum 6 characters',
    minimum4Char: 'Minimum 4 characters',
    minimum3Char: 'Minimum 3 characters',
    minimum11Char: 'Minimum 11 characters',
    minimum10Char: 'Minimum 10 characters',
    max4Char: 'Maximum 4 characters',
    onlyNumbers: 'Can only contain numbers',
    passwordMatch: "Passwords don't match",
    passwordValidation:
      'Password must be between 6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter.',
  },
  loginScreen: {
    title: 'What is your phone number?',
    email: 'Email address',
    password: 'Password',
    supportText: 'Della Manager - to get help contact us at peter.pal@della.hu  | +36 20 421 8158',
    phoneNumberCountryPrefix: 'Country prefix',
    helperText: 'Enter code sent to: {{phoneNumber}}',
    notReceivedText: 'Not received a code? - Resend',
    askPasswordReset: 'Jelszóemlékeztető küldés',
    linkToRegister: "Don't have an account?",
    signUp: 'Sign Up!',
    alreadyHaveAccount: 'Already have an account?',
  },
  homeScreen: {
    tabs: {
      financeTabTitle: 'Pénzügy',
      confirmationTabTitle: 'Igazolások',
      workersTabTitle: 'Diákok',
    },
  },
  registrationScreen: {
    mainText: 'Would you like to use Della?',
    helperText:
      'Please provide us with your business details to register. *(Please fill the company headquarters as address)',
    nameOfTheBusiness: 'Name of the business',
    zip: 'Zip Code',
    city: 'City',
    address: 'Address',
    country: 'Country',
    email: 'E-mail',
    vatNumber: 'VAT number',
    password: 'Password',
    phoneNumber: 'Phone Number',
    registrationNumber: 'Company registration number',
    organizationType: 'Organization type',
    organizationCompany: 'Company',
    organizationStudentOrg: 'Student Organization',
    representative: 'Company representative',
    button: 'Register',
    confirmDetails: 'Confirm details',
    confirmHelperText: 'Please check if your information is correct',
    confirm: 'Confirm',
    confirmPassword: 'Confirm password',
    edit: 'Edit',
    registerStudentOrg: {
      label1: 'I would like to register as a Student Cooperative',
    },
    readAndAcceptTOS: {
      label1: 'I have read and understand the',
      label2: 'Terms and Conditions',
      label:
        'I have read and understand the <a rel="noopener noreferrer" style="color: #a016a3; font-weight: bold;" href="https://della-storage-one.de-fra1.upcloudobjects.com/documents/Della-Worker-AFF.pdf" target="_blank">Terms and Conditions</a>',
      error: {
        required: 'You must accept TOS',
      },
    },
    readAndAcceptPrivacy: {
      label1: 'I consent to the processing of my data in accordance with the',
      label2: 'Privacy Policy',
      label:
        'I consent to the processing of my data in accordance with the <a rel="noopener noreferrer" style="color: #a016a3; font-weight: bold;" href="https://della-storage-one.de-fra1.upcloudobjects.com/documents/Della-Adatkezelesi-Iranyelvek.pdf" target="_blank">Privacy Policy/a>',
      error: {
        required: 'You must accept the Privacy Policy',
      },
    },
  },
  workersScreen: {
    worker: 'Worker',
    phone: 'Phone',
    status: 'Status',
    job: 'Job(s)',
    actions: 'Actions',
    inviteWorkers: 'Invite worker',
    inviteWorkersForm: {
      phoneNumber: 'Phone number',
      name: 'Name',
      invite: 'Invite',
      cancel: 'Cancel',
      success: 'Worker has been invited successfully',
      error: 'There was an error while inviting worker',
      confirmTitle: 'Confirm',
      confirmText: 'You are about to invite a student with the {{phoneNumber}} phone number',
    },
    invitedWorkerList: {
      invitedWorkerList: 'Invited worker list',
      emptyMessage: 'There are no invited workers yet',
      pendingInvite: 'Pending',
      acceptedInvite: 'Accepted',
      confirmDelete: 'Are you sure you want to delete this invite?',
      resendInvite: {
        success: 'Invite resend',
        error: 'Failed to resend invite',
      },
      resendButtonLabel: 'Resend',
      removeButtonLabel: 'Remove',
      removeWorker: {
        success: 'Worker removed',
        error: 'Failed to remove worker',
      },
    },
  },
  supportScreen: {
    headerTitle: 'Support',
    helperText: 'If you have any questions feel free to call us at +36 20 421-8158',
    createMock: 'Create or clear mockdata',
    createMockDataButton: 'Create mockdata',
    clearMockDataButton: 'Clear all account data & logout',
    mockDataCreationSuccess: 'Mock data creation was success',
    mockdataCreationFailed: 'Mock data creation was FAILED',
    mockdataCleanFailed: 'Mock data clear was FAILED',
    mockDataClearSuccess: 'Mock data clear was success',
  },
  notificationScreen: {
    headerTitle: 'Notifications',
    noNotification: '(Your notification list is empty.)',
  },
  confirmationScreen: {
    title: 'Confirmations',
    approved: 'Approved',
    noConfirmation: 'You do not have pending confirmations.',
    tableHeader: {
      job: 'Job',
      worker: 'Worker',
      date: 'Date',
      workerAccepted: 'Worker approval',
      wage: 'Hourly rate (net)',
      hours: 'Hours',
      wageSum: 'Netto bér össz.',
      actions: 'Actions',
    },
    confirmationCard: {
      role: 'Role: ',
      workerName: 'Worker name: ',
      location: 'Job location: ',
      jobStarted: 'Job started: ',
      hoursWorked: 'Hours worked',
      accept: 'Accept',
      workerConfirmed: 'Worker already confirmed {{hoursWorked}}',
      workerNotConfirmed: 'Worker has not yet confirmet',
      managerConfirmed: '{{name}} has confirmed {{hoursWorked}} hours',
      managerNotConfirmed: "Manager hasn't confirmed the hours for the worker yet",
      status: 'The student receives {{wage}} HUF (Net) for working {{workingHours}} hours',
    },
    emptyList: '(You have no panding confirmation)',
  },
  financeScreen: {
    account: 'Account',
    totalTransactions: 'Total transactions: {{transactionCount}}',
    companyBalance: '{{balance}}',
    transactionList: 'Latest History',
    transactionDetails: 'Transaction details',
    transactionAmount: '{{amount}} HUF',
    transactionDate: '{{date}}',
    balanceAllHistory: 'All History',
    balanceShowLess: 'Show less',
    taxAlert: '* The amount shown is a net amount, for which we charge 27% VAT.',
    youHaveNoTransaction: '(You have no transactions)',
  },
  profileScreen: {
    userName: 'Manager Full Name:',
    userEmail: 'Email:',
    userPhoneNumber: 'Phone Number:',
    companyName: 'Company:',
    companyPostCode: 'Post Code:',
    companyCity: 'Name of public area:',
    companyAddressLine1: 'Address:',
    companyCountry: 'Country:',
    companyEmail: 'E-mail of company:',
    companyVatNumber: 'VAT Number:',
    companyNumber: 'Company registration number:',
    info: 'Profile information',
  },
  workerProfile: {
    birthDay: 'Date of birth',
    hasPaidStudentOrg: 'Has valid membership at student organization',
    mothersName: 'Mothers name',
    socialSecurityNumber: 'Social Security Number',
    status: 'Status',
    studentIdNumber: 'Student ID Number',
    taxNumber: 'Tax Number',
    resend: 'Resend invite',
    nationality: 'Nationality',
    address: 'Address',
    gender: 'Gender',
    interview: 'Video interview',
    info: 'Worker information',
  },
  notInvitedScreen: {
    helperText: 'Thank you for the registration, we will invite zou soon.',
    description:
      'Della platform now working in Beta-version, but do not worry. We will invite you as soon as possible. If you want to join immediately, please call our colleague: +36 20 421 8158',
  },
  workerCard: {
    date: 'Job offer sent: {{date}}',
    cancelWorker: 'Cancel Worker',
    profile: 'Profile',
    jobOfferSent: 'Job offer sent',
    documentReview: 'Documents under review',
    active: 'Active',
    inActive: 'Inactive',
    newApplicant: 'New applicant',
    more: 'more',
  },
};
