import React, { useState, useEffect, useCallback, SyntheticEvent } from 'react';
import {
  Grid,
  FormControl,
  Select,
  MenuItem,
  TextField,
  CircularProgress,
  Icon,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { Formik, Field, Form } from 'formik';
import { Add } from '@mui/icons-material';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';

import { Input } from 'src/components/Input';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { FormInputField } from 'src/components/FormInputField/FormInputField';
import { FormTextArea } from 'src/components/FormInputField/FormTextArea';
import { creatJobSchema } from './validationSchemas';
import { CreateRoleForm } from './CreateRoleForm.types';
import { useStoreState } from 'src/stores';
import { Role } from 'src/DTO/Role.type';
import { FeorNumber } from 'src/DTO/FeorNumber.type';
import { useDebounce } from 'src/utils/useDebounce';
import { useSearchFeorNumber } from 'src/api/hooks/useJob';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';

const INIT_ROLE_DATA = {
  name: '',
  description: '',
  location: '',
  companyProfile: '',
  feor: undefined,
  postCode: '',
  city: '',
  address: '',
};

export interface CreateRoleProps {
  onCancel?: () => void;
  onSubmit: (roleData: CreateRoleForm) => void;
  roleId?: string;
  newRoleData?: CreateRoleForm | Role;
}

export const CreateRole: React.FC<CreateRoleProps> = ({
  onCancel,
  onSubmit,
  roleId,
  newRoleData,
}) => {
  const { t } = useTranslation();

  const [searchFeor, { data, loading }] = useSearchFeorNumber();

  const roles = useStoreState(state => state.jobs.roles);

  const [selectedRole, setSelectedRole] = useState<Role | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<readonly FeorNumber[]>([]);
  const [feorSearch, setFeorSearch] = useState('');
  const [selectedFeor, setSelectedFeor] = useState<FeorNumber | undefined>(undefined);
  const [feorError, setFeorError] = useState<boolean>(false);
  const [createNewMode, setCreateNewMode] = useState<boolean>(false);

  const debouncedSearchTerm = useDebounce(feorSearch, 500);

  const isFeorNumber = (value: any): value is FeorNumber => {
    return (
      value &&
      typeof value === 'object' &&
      'code' in value &&
      'text' in value &&
      typeof value.code === 'number' &&
      typeof value.text === 'string'
    );
  };

  const handleInputChange = (
    _event: React.SyntheticEvent<Element, Event>,
    value: string,
    reason?: AutocompleteInputChangeReason,
  ) => {
    if (reason === 'input') {
      setFeorSearch(value);
    }
  };

  const handleOnChange = (
    _event: SyntheticEvent<Element, Event>,
    value: string | FeorNumber | null,
  ) => {
    if (isFeorNumber(value)) {
      setSelectedFeor(value);
    } else {
      setSelectedFeor(undefined);
    }
  };

  useEffect(() => {
    if (selectedFeor === undefined || !selectedFeor || selectedFeor === null) {
      searchFeor({ variables: { feorSearch: '' } });
    }
  }, [selectedFeor]);

  useEffect(() => {
    if (
      newRoleData?.feor &&
      selectedFeor?.label !== `${newRoleData.feor.code}. ${newRoleData.feor.text}`
    ) {
      setSelectedFeor({
        ...newRoleData.feor,
        label: `${newRoleData.feor.code}. ${newRoleData.feor.text}`,
      });
    }
  }, [newRoleData]);

  useEffect(() => {
    debouncedSearchTerm
      ? searchFeor({ variables: { feorSearch: String(debouncedSearchTerm) } })
      : searchFeor({ variables: { feorSearch: '' } });
  }, [debouncedSearchTerm]);

  useEffect(() => {
    if (data) {
      setOptions(data.map(feor => ({ ...feor, label: `${feor.code}. ${feor.text}` })));
    }
  }, [data]);

  const getInitialRoleData = () => {
    const role = roles.find(role => role.id === roleId);

    if (!role) {
      return newRoleData ? { ...newRoleData } : { ...INIT_ROLE_DATA };
    }

    if (!selectedRole) {
      setSelectedRole(role);
    }

    return {
      name: newRoleData?.name || '',
      description: newRoleData?.description || '',
      location: newRoleData?.location || '',
      companyProfile: newRoleData?.companyProfile || '',
      feor: newRoleData?.feor || undefined,
      postCode: newRoleData?.postCode || '',
      city: newRoleData?.city || '',
      address: newRoleData?.address || '',
    };
  };

  const selectRole = useCallback(
    (
      selectedRoleId: string,
      setValues: (roleData: CreateRoleForm, shouldValidate: boolean) => void,
    ) => {
      const role = roles.find(role => role.id === selectedRoleId);

      if (!role) {
        return;
      }

      if (role.feor) {
        setSelectedFeor({ ...role.feor, label: `${role.feor.code}. ${role.feor.text}` });
      }

      const selectedRoleData = {
        ...role,
        postCode: role.postCode || '',
        city: role.city || '',
        address: role.address || '',
      };

      setSelectedRole(selectedRoleData);
      setValues(selectedRoleData, true);
    },
    [roles],
  );

  const handleAddNewRole = useCallback((setValues: (roleData: CreateRoleForm) => void) => {
    setValues({ ...INIT_ROLE_DATA });
    setSelectedRole(undefined);
    setSelectedFeor(undefined);
    setCreateNewMode(true);
    setFeorSearch('');
  }, []);

  const handleSubmit = useCallback(
    async (data: CreateRoleForm, validateForm: () => Promise<any>) => {
      setFeorError(false);
      try {
        const errors = await validateForm();
        if (Object.keys(errors).length === 0) {
          const submitData = {
            ...data,
            feor: selectedFeor,
          };

          if (!selectedRole || createNewMode) {
            onSubmit(submitData);
            return;
          }

          const submitDataWithId = {
            ...data,
            id: selectedRole.id,
            location: `${data.postCode}, ${data.city}, ${data.address}`,
            feor: selectedFeor,
          };
          onSubmit(submitDataWithId);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [onSubmit, selectedFeor],
  );

  return (
    <Box>
      <Box width="80%" textAlign="center" mx="auto" mb={4}>
        <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
          {!roles || roles.length === 0 || createNewMode ? (
            <Trans>jobPage.createJobModal.create</Trans>
          ) : (
            <Trans>jobPage.createJobModal.chooseOrCreate</Trans>
          )}
        </Typography>
      </Box>
      <Formik
        initialValues={getInitialRoleData()}
        validateOnChange={false}
        validationSchema={creatJobSchema(t)}
        onSubmit={() => {
          console.log('submit');
        }}>
        {({ setValues, values, touched, validateForm, errors, setFieldTouched, isSubmitting }) => {
          return (
            <Grid container spacing={4}>
              <Grid item sm={12} md={6}>
                <Form>
                  {roles && roles.length > 0 && (
                    <Box mb={5}>
                      <FormControl variant="filled" sx={{ width: '100%' }}>
                        <Select
                          input={
                            <Input
                              inputLabel={t('jobPage.createJobForm.selectRole')}
                              icon={{
                                component: <Icon>expand_more</Icon>,
                                direction: 'right',
                              }}
                            />
                          }
                          onChange={event => selectRole(event.target.value as string, setValues)}
                          value={!createNewMode ? (selectedRole ? selectedRole.id : '') : ''}>
                          <MenuItem
                            onClick={() => handleAddNewRole(setValues)}
                            sx={{ marginBottom: 1 }}>
                            <Add color="primary" sx={{ marginRight: 1 }} />
                            <Trans>generic.add</Trans>
                          </MenuItem>
                          {roles.map(role => (
                            <MenuItem
                              key={role.id}
                              value={role.id}
                              onClick={() => setCreateNewMode(false)}>
                              {role.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  )}
                  <Field
                    label={t('jobPage.createRoleForm.jobTitle')}
                    name="name"
                    component={FormInputField}
                    variant="outlined"
                    error={touched.name || isSubmitting ? errors.name : undefined}
                    helperText={errors.name}
                  />
                  <Box mt={2} mb={4}>
                    <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
                      <Typography
                        component="label"
                        variant="caption"
                        fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
                        textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
                        FEOR
                      </Typography>
                    </Box>
                    <Autocomplete
                      open={open}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      onChange={handleOnChange}
                      onInputChange={handleInputChange}
                      options={options}
                      loading={loading}
                      freeSolo={true}
                      forcePopupIcon={true}
                      disableClearable={!selectedFeor}
                      value={selectedFeor ? selectedFeor.label : ''}
                      renderInput={params => (
                        <TextField
                          {...params}
                          error={feorError}
                          helperText={feorError && t('validation.required')}
                          variant="outlined"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Field
                        label={t('generic.postCode')}
                        name="postCode"
                        component={FormInputField}
                        variant="outlined"
                        error={touched.postCode || isSubmitting ? errors.postCode : undefined}
                        helperText={errors.postCode}
                        inputProps={{ maxLength: 4 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Field
                        label={t('generic.city')}
                        name="city"
                        component={FormInputField}
                        variant="outlined"
                        error={touched.city || isSubmitting ? errors.city : undefined}
                        helperText={errors.city}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        label={`${t('generic.street')}/${t('generic.streetNumber')}`}
                        name="address"
                        component={FormInputField}
                        variant="outlined"
                        error={
                          touched.address && errors.address && !isSubmitting
                            ? errors.address
                            : undefined
                        }
                        helperText={errors.address}
                      />
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
              <Grid item sm={12} md={6}>
                <Box>
                  <Field
                    label={t('jobPage.createRoleForm.companyProfile')}
                    name="companyProfile"
                    component={FormTextArea}
                    variant="outlined"
                    error={
                      touched.companyProfile || isSubmitting ? errors.companyProfile : undefined
                    }
                    helperText={errors.companyProfile}
                    sx={{ width: '100%' }}
                  />
                  <Field
                    label={t('jobPage.createRoleForm.jobDescription')}
                    name="description"
                    component={FormTextArea}
                    variant="outlined"
                    error={touched.description || isSubmitting ? errors.description : undefined}
                    helperText={errors.description}
                    sx={{ width: '100%' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  {!!onCancel && (
                    <Box mr={3}>
                      <Button onClick={onCancel}>
                        <Trans>generic.cancel</Trans>
                      </Button>
                    </Box>
                  )}
                  <Box ml={3}>
                    <Button
                      variant={BUTTON_VARIANT.GRADIENT}
                      color={BUTTON_COLOR.DARK}
                      type="submit"
                      onClick={() => {
                        handleSubmit(values, validateForm);
                        Object.keys(values).forEach(field => {
                          setFieldTouched(field, true);
                        });
                      }}>
                      <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          );
        }}
      </Formik>
    </Box>
  );
};
