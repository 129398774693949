import React, { useEffect, useCallback, useMemo, useState } from 'react';
import { Grid } from '@mui/material';
import { Trans } from 'react-i18next';

import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { BUTTON_COLOR, BUTTON_SIZE, BUTTON_VARIANT } from 'src/components/Button/types';
import { JobCard } from 'src/components/Cards/JobCard';
import { DashboardLayout } from 'src/components/layouts/LayoutContainers/DashboardLayout';
import { CreateJobDialog } from './components/CreateJobDialog';
import { useActiveRoles } from 'src/api/hooks/useRole';
import { Footer } from 'src/components/Footer';
import { useGetJobs } from 'src/api/hooks/useJob';
import { useStoreState, useStoreActions } from 'src/stores';
import { EmptyJobView } from './components/EmptyJobView';
import { JOB_STATUS } from 'src/DTO/JobStatus.enum';

export const JobPage: React.FC = () => {
  /* LOCAL STATE */
  const [isCreateJobDialogOpen, setIsCreateJobDialogOpen] = useState(false);

  /* GLOBAL STATE */
  const roles = useStoreState(state => state.jobs.roles);
  const jobs = useStoreState(state => state.jobs.jobs);

  const setRoles = useStoreActions(actions => actions.jobs.setRoles);
  const setJobs = useStoreActions(actions => actions.jobs.setJobs);

  const [getActiveRoles, { data: activeRoles }] = useActiveRoles();
  const [getJobs, { data: allJobs }] = useGetJobs();

  useEffect(
    useCallback(() => {
      getActiveRoles();
      getJobs();
    }, []),
    [],
  );

  useEffect(() => {
    if (activeRoles && activeRoles !== roles) {
      setRoles(activeRoles);
    }
  }, [activeRoles]);

  useEffect(() => {
    if (allJobs && allJobs !== jobs) {
      setJobs(allJobs);
    }
  }, [allJobs]);

  const isJobListEmpty = jobs && jobs.length === 0;

  const activeJobs = useMemo(
    () => jobs.filter(job => job.status !== JOB_STATUS.TERMINATED),
    [jobs],
  );

  const inactiveJobs = useMemo(
    () => jobs.filter(job => job.status === JOB_STATUS.TERMINATED),
    [jobs],
  );

  return (
    <DashboardLayout>
      <Box pb={2}>
        {isJobListEmpty && <EmptyJobView />}
        {!isJobListEmpty && (
          <>
            <Grid container>
              <Grid item xs={12}>
                <Box>
                  <Typography variant="h5">
                    <Trans>jobPage.activeJobs</Trans>
                  </Typography>
                </Box>
                <Box mb={1}>
                  <Button
                    variant={BUTTON_VARIANT.GRADIENT}
                    size={BUTTON_SIZE.SMALL}
                    color={BUTTON_COLOR.INFO}
                    onClick={() => setIsCreateJobDialogOpen(true)}>
                    <Trans>jobPage.createJob</Trans>
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box mt={{ xs: 1, lg: 3 }} mb={8}>
              <Grid container spacing={3}>
                {activeJobs.map(job => (
                  <Grid item xs={12} md={6} lg={4} key={job.id}>
                    <JobCard job={job} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            {inactiveJobs.length > 0 && (
              <>
                <Grid container>
                  <Grid item xs={12} md={8}>
                    <Box>
                      <Typography variant="h5">
                        <Trans>jobPage.inactiveJobs</Trans>
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Box mt={{ xs: 1, lg: 3 }} mb={5}>
                  <Grid container spacing={3}>
                    {inactiveJobs.map(job => (
                      <Grid item xs={12} md={6} lg={4} key={job.id}>
                        <JobCard job={job} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </>
            )}
          </>
        )}
      </Box>
      <Footer />
      <CreateJobDialog open={isCreateJobDialogOpen} setOpen={setIsCreateJobDialogOpen} />
    </DashboardLayout>
  );
};
