import React, { useState } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  Icon,
  SelectChangeEvent,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Input } from 'src/components/Input';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { Typography } from 'src/components/Typography';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { DrivingLicenseTypes } from '../Job.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export interface CreateJobStep1Props {
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: (jobData: NewJobData) => void;
  newJobData: NewJobData;
}

export const CreateJobStep2: React.FC<CreateJobStep1Props> = ({
  handlePreviousButtonClick,
  handleNextButtonClick,
  newJobData,
}) => {
  const { t } = useTranslation();

  // LOCAL STATE
  const [hungarianSkillsNeeded, setHungarianSkillsNeeded] = useState<boolean>(
    newJobData?.hungarianSkillsNeeded || false,
  );
  const [englishSkillsNeeded, setEnglishSkillsNeeded] = useState<boolean>(
    newJobData?.englishSkillsNeeded || false,
  );
  const [interviewNeeded, setInterviewNeeded] = useState<boolean>(
    newJobData?.interviewNeeded || true,
  );
  const [documents, setDocuments] = useState<string>(newJobData?.documents || '');
  const [extraSkills, setExtraSkills] = useState<string>(newJobData?.extraSkills || '');
  const [drivingLicense, setDrivingLicense] = useState<DrivingLicenseTypes>(
    newJobData.drivingLicense || DrivingLicenseTypes.None,
  );

  const handleHungarianSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHungarianSkillsNeeded(event.currentTarget.checked);
  };

  const handleEnglishSkillsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEnglishSkillsNeeded(event.currentTarget.checked);
  };

  const handleInterviewNeededChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterviewNeeded(event.currentTarget.checked);
  };

  const handleDrivingLicense = (event: SelectChangeEvent<DrivingLicenseTypes>) => {
    setDrivingLicense(event.target.value as DrivingLicenseTypes);
  };

  const handleDocumentsChange = (event: React.FormEvent<HTMLInputElement>) => {
    setDocuments(event.currentTarget.value);
  };

  const handleExtraSkillsChange = (event: React.FormEvent<HTMLInputElement>) => {
    setExtraSkills(event.currentTarget.value);
  };

  const onSubmit = () => {
    try {
      handleNextButtonClick({
        ...newJobData,
        hungarianSkillsNeeded,
        documents,
        extraSkills,
        drivingLicense,
        englishSkillsNeeded,
        interviewNeeded,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox checked={hungarianSkillsNeeded} onChange={handleHungarianSkillsChange} />
            }
            label={t('jobPage.createJobForm.hungarianSkills') as string}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <FormControlLabel
            control={
              <Checkbox checked={englishSkillsNeeded} onChange={handleEnglishSkillsChange} />
            }
            label={t('jobPage.createJobForm.englishSkills') as string}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <FormControlLabel
            control={<Checkbox checked={interviewNeeded} onChange={handleInterviewNeededChange} />}
            label={t('jobPage.createJobForm.isInterviewNeeded') as string}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <Typography
            component="label"
            variant="caption"
            fontWeight={TYPOGRAPHY_WEIGHT.BOLD}
            textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}>
            <Trans>jobPage.createJobForm.drivingLicense</Trans>
          </Typography>
        </Box>
        <Select
          input={
            <Input
              icon={{
                component: <Icon>expand_more</Icon>,
                direction: 'right',
              }}
            />
          }
          value={drivingLicense}
          onChange={handleDrivingLicense}>
          <MenuItem value={DrivingLicenseTypes.None} sx={{ marginBottom: 1 }}>
            <Trans>jobPage.createJobForm.noLicense</Trans>
          </MenuItem>
          <MenuItem value={DrivingLicenseTypes.Car} sx={{ marginBottom: 1 }}>
            <Trans>jobPage.createJobForm.car</Trans>
          </MenuItem>
          <MenuItem value={DrivingLicenseTypes.MotorCycle}>
            <Trans>jobPage.createJobForm.motorCycle</Trans>
          </MenuItem>
        </Select>
      </Grid>
      <Grid item xs={12}>
        <Box mt={4} mb={4}>
          <Input
            inputLabel={t('jobPage.createJobForm.documents')}
            placeholder={t('jobPage.createJobForm.documentsPlaceholder')}
            value={documents}
            onChange={handleDocumentsChange}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            inputLabel={t('jobPage.createJobForm.extraSkills')}
            value={extraSkills}
            onChange={handleExtraSkillsChange}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={onSubmit}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
