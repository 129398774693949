import * as Yup from 'yup';
import { TFunction } from 'i18next';

const JOB_TITLE_MIN_LENGTH = 3;
const JOB_DESCRIPTION_MIN_LENGTH = 15;

export const creatJobSchema = (t: TFunction) =>
  Yup.object().shape({
    name: Yup.string().min(
      JOB_TITLE_MIN_LENGTH,
      t('validation.minLength', { number: JOB_TITLE_MIN_LENGTH }),
    ),
    description: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    companyProfile: Yup.string().min(
      JOB_DESCRIPTION_MIN_LENGTH,
      t('validation.minLength', { number: JOB_DESCRIPTION_MIN_LENGTH }),
    ),
    postCode: Yup.string()
      .min(4, t('validation.minimum4Char'))
      .matches(/^[0-9]+$/, t('validation.onlyNumbers')),
    city: Yup.string().min(3, t('validation.minimum3Char')),
    address: Yup.string().min(3, t('validation.minimum3Char')),
  });
