import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';

import { Input } from 'src/components/Input';
import { Typography } from 'src/components/Typography';
import { Box } from 'src/components/Box';
import { Button } from 'src/components/Button';
import { NewJobData } from 'src/api/hooks/useJob.types';
import { BUTTON_COLOR, BUTTON_VARIANT } from 'src/components/Button/types';
import { TYPOGRAPHY_WEIGHT } from 'src/components/Typography/types';

export interface CreateJobStep5Props {
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: (jobData: NewJobData) => void;
  newJobData: NewJobData;
}

export const CreateJobStep5: React.FC<CreateJobStep5Props> = ({
  handlePreviousButtonClick,
  handleNextButtonClick,
  newJobData,
}) => {
  const { t } = useTranslation();

  // LOCAL STATE
  const [arriveInfo, setArriveInfo] = useState<string>(newJobData?.arriveInfo || '');
  const [equipmentInfo, setEquipmentInfo] = useState<string>(newJobData?.equipmentInfo || '');

  const [arriveInfoError, setArriveInfoError] = useState<boolean>(false);
  const [equipmentInfoError, setEquipmentInfoError] = useState<boolean>(false);

  const handleEquipmentInfo = (event: React.FormEvent<HTMLInputElement>) => {
    setEquipmentInfo(event.currentTarget.value);
    setEquipmentInfoError(event.currentTarget.value.trim().length < 15);
  };

  const handleArriveInfo = (event: React.FormEvent<HTMLInputElement>) => {
    setArriveInfo(event.currentTarget.value);
    setArriveInfoError(event.currentTarget.value.trim().length < 15);
  };

  const isFormValid = (): boolean => {
    let isValid = true;

    if (equipmentInfo && equipmentInfo.trim().length < 15) {
      setEquipmentInfoError(true);
      isValid = false;
    } else {
      setEquipmentInfoError(false);
    }

    if (arriveInfo && arriveInfo.trim().length < 15) {
      setArriveInfoError(true);
      isValid = false;
    } else {
      setArriveInfoError(false);
    }

    return isValid;
  };

  const onSubmit = () => {
    if (isFormValid()) {
      handleNextButtonClick({
        ...newJobData,
        arriveInfo,
        equipmentInfo,
      });
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box width="80%" textAlign="center" mx="auto" mb={4}>
          <Box mb={1}>
            <Typography variant="h5" fontWeight={TYPOGRAPHY_WEIGHT.REGULAR}>
              <Trans>jobPage.createJobModal.instructions</Trans>
            </Typography>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            rows={8}
            multiline
            inputLabel={t('jobPage.createJobForm.equipmentInfo')}
            placeholder={t('jobPage.createJobForm.equimentInfoPlaceHolder')}
            value={equipmentInfo}
            onChange={handleEquipmentInfo}
            error={equipmentInfoError}
            helperText={equipmentInfoError ? t('validation.minLength', { number: 15 }) : undefined}
            onBlur={isFormValid}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box mb={4}>
          <Input
            rows={8}
            multiline
            inputLabel={t('jobPage.createJobForm.arriveInfo')}
            placeholder={t('jobPage.createJobForm.arrivePlaceholder')}
            value={arriveInfo}
            onChange={handleArriveInfo}
            error={arriveInfoError}
            helperText={arriveInfoError ? t('validation.minLength', { number: 15 }) : undefined}
            onBlur={isFormValid}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box mr={3}>
            <Button onClick={handlePreviousButtonClick}>
              <Trans>generic.back</Trans>
            </Button>
          </Box>
          <Box ml={3}>
            <Button
              variant={BUTTON_VARIANT.GRADIENT}
              color={BUTTON_COLOR.DARK}
              type="submit"
              onClick={onSubmit}>
              <Trans>jobPage.createRoleForm.submitButtonLabel</Trans>
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
