import { FeorNumber } from 'src/DTO/FeorNumber.type';
import { Job, JobApplication } from 'src/DTO/Job.type';
import { JobConfirmation } from 'src/DTO/JobConfirmation.type';
import { JobOffer } from 'src/DTO/JobOffer.type';
import { useApi } from './useApi';
import { BaseRequest, BaseHookRequest } from './useApi.types';
import {
  AcceptConfirmationPayload,
  CreateJobOfferPayload,
  CreateJobOfferResponse,
  CreateJobPayload,
  CreateJobOfferBatchResponse,
  CreateJobOfferBatchPayload,
  GetJobByIdPayload,
  TerminateWorkerFromJobPayload,
  TerminateWorkerFromJobResponse,
  CalculateJobWagePayload,
  SearchFeorPayload,
  JobWageResponse,
  CancelJobApplicationPayload,
  TerminateJobResponse,
  TerminateJobPayload,
} from './useJob.types';
import { useStoreState } from 'src/stores';

export const useCreateJob: BaseHookRequest<Job, CreateJobPayload> = params => {
  const [{ postRequest }, state] = useApi<Job>(params);
  const createJob: BaseRequest<CreateJobPayload> = details => {
    postRequest<CreateJobPayload>('/manager/job/save', details?.variables);
  };

  return [createJob, state];
};

export const useCreateJobOffer: BaseHookRequest<CreateJobOfferResponse, CreateJobOfferPayload> =
  params => {
    const [{ postRequest }, state] = useApi<CreateJobOfferResponse>(params);
    const createJobOffer: BaseRequest<CreateJobOfferPayload> = details => {
      postRequest<CreateJobOfferPayload>('/manager/offer/create', details?.variables);
    };

    return [createJobOffer, state];
  };

export const useCreateJobOfferBatch: BaseHookRequest<
  CreateJobOfferBatchResponse,
  CreateJobOfferBatchPayload
> = params => {
  const [{ postRequest }, state] = useApi<CreateJobOfferBatchResponse>(params);
  const createJobOfferBatch: BaseRequest<CreateJobOfferBatchPayload> = details => {
    postRequest<CreateJobOfferBatchPayload>('/manager/offer/create/batch', details?.variables);
  };

  return [createJobOfferBatch, state];
};

export const useGetAllJobConfirmations: BaseHookRequest<JobConfirmation[], null> = params => {
  const [{ getRequest }, state] = useApi(params);
  const getActiveJobConfirmations = () => getRequest('/manager/confirmation/pending');
  return [getActiveJobConfirmations, state];
};

export const useAcceptConfirmation: BaseHookRequest<JobConfirmation, AcceptConfirmationPayload> =
  params => {
    const [{ postRequest }, state] = useApi(params);
    const acceptConfirmation: BaseRequest<AcceptConfirmationPayload> = details =>
      postRequest<AcceptConfirmationPayload>(
        `/manager/confirmation/accept/${details?.variables.confirmationId}`,
        details?.variables,
      );
    return [acceptConfirmation, state];
  };

export const useGetJobs: BaseHookRequest<Job[], null> = params => {
  const [{ getRequest }, state] = useApi<Job[]>(params);
  const getJobs = () => {
    getRequest('/manager/job/all');
  };
  return [getJobs, state];
};

export const useGetJobOffers: BaseHookRequest<JobOffer[], null> = params => {
  const [{ getRequest }, state] = useApi<JobOffer[]>(params);
  const getJobOffers = () => {
    getRequest('/manager/offer/all');
  };
  return [getJobOffers, state];
};

export const useGetJobById: BaseHookRequest<Job, GetJobByIdPayload> = params => {
  const [{ getRequest }, state] = useApi<Job>(params);
  const getJobById: BaseRequest<GetJobByIdPayload> = details => {
    getRequest(`/manager/job/details/${details?.variables.jobId}`);
  };
  return [getJobById, state];
};

export const useTerminateWorkers: BaseHookRequest<
  TerminateWorkerFromJobResponse,
  TerminateWorkerFromJobPayload
> = params => {
  const [{ postRequest }, state] = useApi(params);
  const terminateWorkers: BaseRequest<TerminateWorkerFromJobPayload> = details =>
    postRequest<TerminateWorkerFromJobPayload>('/manager/job/terminateworkers', details?.variables);
  return [terminateWorkers, state];
};

export const useCalculateJobWage: BaseHookRequest<JobWageResponse, CalculateJobWagePayload> =
  params => {
    const [{ postRequest }, state] = useApi(params);
    const user = useStoreState(state => state.user.data);
    const rake = user && user.companies ? Number(user.companies[0].rake) : undefined;

    const calculateJobWage: BaseRequest<CalculateJobWagePayload> = details =>
      postRequest<CalculateJobWagePayload>('/manager/job/calculateJobWage', {
        ...details?.variables,
        rake,
      });
    return [calculateJobWage, state];
  };

export const useSearchFeorNumber: BaseHookRequest<FeorNumber[], SearchFeorPayload> = params => {
  const [{ postRequest }, state] = useApi(params);
  const searchFeor: BaseRequest<SearchFeorPayload> = details =>
    postRequest<SearchFeorPayload>('/manager/job/searchFeor', details?.variables);
  return [searchFeor, state];
};

export const useGetJobApplications: BaseHookRequest<JobApplication[], GetJobByIdPayload> =
  params => {
    const [{ getRequest }, state] = useApi<JobApplication[]>(params);
    const getJobApplications: BaseRequest<GetJobByIdPayload> = details => {
      getRequest(`/manager/application/${details?.variables.jobId}`);
    };
    return [getJobApplications, state];
  };

export const useCancelJobApplication: BaseHookRequest<JobApplication, CancelJobApplicationPayload> =
  params => {
    const [{ postRequest }, state] = useApi<JobApplication>(params);
    const cancelApplication: BaseRequest<CancelJobApplicationPayload> = details => {
      postRequest(`/manager/application/decline/${details?.variables.applicationId}`);
    };
    return [cancelApplication, state];
  };

export const useAcceptJobApplication: BaseHookRequest<JobApplication, CancelJobApplicationPayload> =
  params => {
    const [{ postRequest }, state] = useApi<JobApplication>(params);
    const acceptApplication: BaseRequest<CancelJobApplicationPayload> = details => {
      postRequest(`/manager/application/accept/${details?.variables.applicationId}`);
    };
    return [acceptApplication, state];
  };

export const useTerminateJob: BaseHookRequest<TerminateJobResponse, TerminateJobPayload> =
  params => {
    const [{ postRequest }, state] = useApi<TerminateJobResponse>(params);

    const terminateRole: BaseRequest<TerminateJobPayload> = details => {
      postRequest<TerminateJobPayload>('/manager/job/terminate', details?.variables);
    };

    return [terminateRole, state];
  };
