import React from 'react';

import { Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

import { Box } from 'src/components/Box';
import { Typography } from 'src/components/Typography';

import { TYPOGRAPHY_COLORS, TYPOGRAPHY_TRANSFORM, TYPOGRAPHY_WEIGHT } from '../Typography/types';
import { Job } from 'src/DTO/Job.type';
import Settings from 'src/assets/icons/Settings';

type JobCardProps = {
  job: Job;
};

function JobCard({ job }: JobCardProps) {
  const history = useHistory();

  const handleJobDetailNavigation = () => {
    history.push(`/job/${job.id}`);
  };

  return (
    <Card sx={{ cursor: 'pointer' }} onClick={() => handleJobDetailNavigation()}>
      <Box p={2}>
        <Box display="flex" alignItems="center">
          <Settings size="30px" />
          <Box ml={2} lineHeight={0}>
            <Box mb={1} lineHeight={0}>
              <Typography
                variant="h6"
                textTransform={TYPOGRAPHY_TRANSFORM.CAPITALIZE}
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {job.role.name}
              </Typography>
              <Typography
                variant="button"
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                color={TYPOGRAPHY_COLORS.SECONDARY}>
                {job.role.location}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {job.activeWorkersCount && job.activeWorkersCount > -1 ? (
            <Box display="flex" flexDirection="column" lineHeight={0}>
              <Typography variant="button" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {`${job.workerCount} / ${job.activeWorkersCount}`}
              </Typography>
              <Typography
                variant="button"
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                color={TYPOGRAPHY_COLORS.SECONDARY}>
                <Trans>jobPage.worker</Trans>
              </Typography>
            </Box>
          ) : null}
          {job.weeklyHours ? (
            <Box display="flex" flexDirection="column" lineHeight={0}>
              <Typography variant="button" fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}>
                {job.weeklyHours}
              </Typography>
              <Typography
                variant="button"
                fontWeight={TYPOGRAPHY_WEIGHT.MEDIUM}
                color={TYPOGRAPHY_COLORS.SECONDARY}>
                <Trans>jobPage.hoursPerMonth</Trans>
              </Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Card>
  );
}

export { JobCard };
